

<template>
  <section id="dashboard-analytics">
    <b-row class="match-height">
      <b-col class="content-header-left mb-1" cols="12" md="12">
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="content-header-title float-left pr-1 mb-0">
            Team 
        </h2>
            <div class="breadcrumb-wrapper">
              <b-breadcrumb style="font-size: 0.9rem">
                <b-breadcrumb-item to="/">
                  <feather-icon
                    icon="HomeIcon"
                    size="14"
                    class="align-text-top"
                  />
                </b-breadcrumb-item>
                <b-breadcrumb-item active
                >
                  TEAM
                </b-breadcrumb-item>
              </b-breadcrumb>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col>
          <tree/>
        </b-col>
    </b-row>

  </section>
</template>

<script>
import {  
  BRow,
  BCol,
  BBreadcrumb,
  BBreadcrumbItem,
  BDropdown,
  BDropdownItem,
  BButton} from 'bootstrap-vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import Tree from  '../../hr/tree/Tree.vue'


export default {
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    StatisticCardVertical,
    Tree
  },
  data () {
    return {
     
      userData: localStorage.getItem('userData'),
      userRole: ''
    }
  },
  created () {

    // data
    // this.$http.get('/analytics/data').then((response) => {
    //   this.data = response.data
    // })
  },
  mounted () {
    this.userRole = JSON.parse(this.userData).role
  }
}
</script>
